import React from "react";
import HeaderLp from "../HeaderLp";
import "./AboutUsKiwu.css";
// import About1 from "../../../assets/landingpage/about1.png";
// import About2 from "../../../assets/landingpage/about2.png";
// import About3 from "../../../assets/landingpage/about3.png";
// import About4 from "../../../assets/landingpage/financesparalleles.png";
import DownloadAppSection from "../DownloadAppSection";
import FooterLandingpage from "../FooterLandingpage/FooterLandingpage";
import { motion } from "framer-motion";
import ButtonScrollTop from "../ButtonScrollTop";
import { useGetLandingDataQuery } from "../../../utils/api/config/config.api";
import { createMarkup } from "../../../utils/utils";

const AboutUsKiwu = () => {
	const { data } = useGetLandingDataQuery();
	// console.log({ data });

	return (
		<div className="about-us-component">
			<HeaderLp />
			{/* <BreadCrumb title="À propos" pageName="À propos" /> */}
			<section className="container-section-about-us-page">
				<div className="bg-gradient-theme mb-3">
					<motion.div
						initial={{
							y: -50,
							opacity: 0,
						}}
						animate={{
							y: 0,
							opacity: 1,
						}}
						transition={{
							duration: 1,
						}}
						className="content-section-page-about2 "
					>
						<div className="row row-vision-about-us align-items-center">
							<div className="col-md-6 col-left-vision-about mb-3">
								<div
									className="kivu-about-us-paragraph"
									dangerouslySetInnerHTML={createMarkup(data?.about?.section_one_description)}
								/>
							</div>
							<div className="col-md-6 col-left-vision-about mb-3">
								<div className="content-col-left-vision-about">
									<img
										src={data?.about?.section_one_image}
										alt="Logo finances paralleles"
										className="w-100"
										loading="lazy"
									/>
								</div>
							</div>
						</div>
					</motion.div>

					<div className="content-section-page-about">
						<div className="row row-vision-about-us align-items-center mb-5">
							<div className="col-md-6 col-left-vision-about mb-3">
								<motion.div
									initial={{
										x: -50,
										opacity: 0,
									}}
									animate={{
										x: 0,
										opacity: 1,
									}}
									transition={{
										duration: 1,
									}}
									className="content-col-left-vision-about"
								>
									<img
										src={data?.about?.section_two_image}
										alt="Notre vision"
										className="img-about-us-kiwu"
										loading="lazy"
									/>
								</motion.div>
							</div>
							<div className="col-md-6 col-right-vision-about mb-3">
								<motion.div
									initial={{
										x: 50,
										opacity: 0,
									}}
									animate={{
										x: 0,
										opacity: 1,
									}}
									transition={{
										duration: 1,
									}}
									className="content-col-right-vision-about"
								>
									<h2 className="title-section-about-kiwu">{data?.about?.section_two_title}</h2>
									<div className="content-text-section-about-kiwu pt-4">
										<div
											className="kivu-about-us-paragraph"
											dangerouslySetInnerHTML={createMarkup(
												data?.about?.section_two_description
											)}
										/>
									</div>
								</motion.div>
							</div>
						</div>
						<div className="row row-vision-about-us align-items-center pt-5">
							<div className="col-md-6 col-left-vision-about mb-3">
								<motion.div
									initial={{
										x: -50,
										opacity: 0,
									}}
									whileInView={{
										x: 0,
										opacity: 1,
									}}
									transition={{
										duration: 1,
									}}
									className="content-col-left-vision-about"
								>
									<h2 className="title-section-about-kiwu">{data?.about?.section_three_title}</h2>
									<div className="content-text-section-about-kiwu pt-4">
										<div
											className="kivu-about-us-paragraph"
											dangerouslySetInnerHTML={createMarkup(
												data?.about?.section_three_description
											)}
										/>
									</div>
								</motion.div>
							</div>
							<div className="col-md-6 col-right-vision-about mb-3">
								<motion.div
									initial={{
										x: 50,
										opacity: 0,
									}}
									whileInView={{
										x: 0,
										opacity: 1,
									}}
									transition={{
										duration: 1,
									}}
									className="content-col-right-vision-about"
								>
									<img
										src={data?.about?.section_three_image}
										alt="Nos objectifs"
										className="img-about-us-kiwu"
										loading="lazy"
									/>
								</motion.div>
							</div>
						</div>
					</div>
					<motion.div
						initial={{ opacity: 0, y: -50, x: -50 }}
						whileInView={{ opacity: 1, y: 0, x: 0 }}
						viewport={{ once: true }}
						transition={{
							duration: 2,
						}}
						className="how-it-work-section"
					>
						<div className="row row-how-it-work-kiwu align-items-center">
							<div className="col-lg-6 col-left-how-it-work-kiwu">
								<div className="content-col-left-how-it-work-kiwu">
									<h2 className="title-section-how-it-work-kiwu">{data?.about?.section_four_title}</h2>
									<div className="content-text-how-it-work-section">
										<div
											className="kivu-about-us-paragraph"
											dangerouslySetInnerHTML={createMarkup(
												data?.about?.section_four_description
											)}
										/>
									</div>
								</div>
							</div>
							<div className="col-lg-6 col-right-how-it-work-kiwu ps-0">
								<div className="content-col-right-how-it-work-kiwu">
									<img
										src={data?.about?.section_four_image}
										alt="Ce que nous faisons"
										className="img-how-it-work-section"
										loading="lazy"
									/>
								</div>
							</div>
						</div>
					</motion.div>
				</div>
				<DownloadAppSection />
				<ButtonScrollTop />
				<FooterLandingpage />
			</section>
		</div>
	);
};

export default AboutUsKiwu;
